@import "~@ng-select/ng-select/themes/default.theme.css";
/* @import '../src/assets/tarteaucitron/css/tarteaucitron.css'; */
@font-face {
  font-family: "Bryant Naos Medium";
  src: url("../src/assets/Fonts/Bryant-Naos-Medium.otf");
}

html, body {
  margin: 0;          /* Elimina los márgenes por defecto */
  padding: 0;         /* Elimina los rellenos por defecto */
  width: 100%;        /* Ocupa el 100% del ancho de la pantalla */
  height: 100%;       /* Ocupa el 100% de la altura de la pantalla */
  box-sizing: border-box; /* Asegura que el padding y border se incluyan en el tamaño total */
  overflow-x: hidden; /* Oculta el desbordamiento horizontal para evitar barras de desplazamiento */
}

#tarteaucitronManager {
  visibility: hidden !important;
}
#tac_title {
  visibility: hidden !important;
}

#tarteaucitronAlertBig:before {
  content: 'NAOS Cookies' !important;
}

.tarteaucitronDeny {
  background-color: #637691 !important;
  color: white !important;
}

#tarteaucitronAlertBig {
  border-radius: 0px !important;
  /* display: flex !important;
  flex-direction: row !important; */
}

#tarteaucitronCloseAlert {
    background: #D9E0E1 !important;
    font-weight: 500 !important;
    color: black !important;
    cursor: pointer !important;
    display: inline-block !important;
    font-size: 16px !important;
    line-height: 1.2 !important;
    padding: 1.6rem 3.8rem !important;
    text-decoration: none !important;
    margin: 5px !important;
    text-align: center !important;
    white-space: nowrap !important;
}


#tarteaucitronAllow {
  background: none !important;
}


.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040 !important;
  width: 100vw !important;
  height: 140vh !important;
  background-color: #000;
}

#tarteaucitronBack {
  width: 100% !important;
}

.mensajeVerde{
  border: 1px solid green;
  padding: 5%;
}

.mensajeRojo{
  border: 1px solid red;
  padding: 5%;
}

.input-select-naos {
  -webkit-appearance: none !important;
  -moz-appearance: none;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: silver;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-image: linear-gradient(45deg, transparent 50%,grey 50%),
    linear-gradient(135deg, grey 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  background-color: white;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid grey !important;
}

.input-select-naos option{
  font-family: "Bryant Naos Medium", Bryant;
  font-weight: 500;
}

.fontsearch{
  font-size: 16px !important;
}

* {
  font-family: "Bryant Naos Medium";
}

.cursor-pointer:hover,
.cursor-pointer:active {
  cursor: pointer;
}

.mw-90 {
  max-width: 90% !important;
}

.w-90 {
  width: 90% !important;
}

.mw-80 {
  max-width: 80% !important;
}

.mediktor-blue-dark-button {
  border-radius: 10px;
  background-color: #1f2d39;
  color: #fff;
  border: 2px solid transparent;
  font-size: 15px;
  padding-top: 0.5rem;
  width: 100%;
  max-width: 300px;
  padding: 0 20px;
  height: 50px;
}

.white-box-blue-border {
  border: 1px solid #4374a6;
  background-color: #fff;
  text-align: center;
}

.blue-text {
  color: #4374a6 !important;
  font-size: 20px;
}

.blue-text.bigger {
  color: #4374a6 !important;
  font-size: 30px;
}

.nav-menu-title {
  border-left: 1px solid #393d4d;
  color: #393d4d;
  font-size: 20px;
}

.nav-menu-option {
  border-top: 1px solid #92aecb;
  font-size: 15px;
  color: #92aecb;
}

.nav-menu-option.selected {
  border-top: 1px solid #393d4d;
  color: #393d4d;
}

.nav-menu-option label {
  display: block;
}
.iconInfo {
  margin-left: 5%;
  color: silver;
}
.nav-menu-option label {
  display: block;
}

@media only screen and (min-device-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}

@media only screen and (min-device-width: 568px) and (max-device-width: 1024px) {
  .blue-text.bigger {
    font-size: 25px;
  }
  .hidden-tablet {
    display: none !important;
  }
}

@media only screen and (max-device-width: 568px) {
  .blue-text {
    font-size: 15px;
  }
  .blue-text.bigger {
    font-size: 20px;
  }
  .nav-menu-title {
    border-left: none !important;
    font-size: 18px;
  }
  .nav-menu-option label {
    display: inline-block;
  }
  .iconoSitio,
  .iconInfo,
  .nav-menu-option {
    font-size: 12px;
  }
  .starScore {
    width: 20px;
    height: auto;
  }

  .block-mobile {
    display: block !important;
  }

  .hidden-mobile {
    display: none !important;
  }

  .mw-mobile-100 {
    max-width: 100% !important;
  }

  .w-mobile-100 {
    width: 100% !important;
  }
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1249px) {
  .bodyNaos {
    overflow-x: hidden;
  }

  .suggestions-container {
    z-index: 1060 !important;
  }

  .viewport {
    overflow: auto;

    /* Make sure the inner div is not larger than the container
         * so that we have room to scroll.
         */
    max-height: 100%;

    /* Pick an arbitrary margin/padding that should be bigger
         * than the max width of all the scroll bars across
         * the devices you are targeting.
         * padding = -margin
         */
    margin-right: -100px;
    padding-right: 100px;
  }

  .notifier__notification {
    height: 80px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .filaLogo {
    text-align: center;
  }

  .filaLogo:hover {
    cursor: pointer;
  }

  .loader {
    text-align: center;
    margin-top: 20%;
  }

  .filaSubMenu {
    font-size: 24px;
    color: #393d4d;
    border-left: 1px solid #393d4d;
  }

  .ng-select-container {
    border: none !important;
    border-bottom: 1px solid #393d4d !important;
    border-color: silver !important;
  }

  #contenidodiv {
    text-align: center;
  }

  .ng-select {
    border: none !important;
    border-bottom: 1px solid #393d4d !important;
    border-color: silver !important;
  }

  select {
    border: none !important;
    border-bottom: 1px solid grey !important;
  }

  .iconInfo:hover {
    cursor: pointer;
  }

  .popover-body {
    width: 100% !important;
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1249px) {
  .bodyNaos {
    overflow-x: hidden;
  }

  .suggestions-container {
    z-index: 1060 !important;
  }
  #contenidodiv {
    text-align: center;
  }

  .bodyNaos::-webkit-scrollbar {
    width: 10px; /* Tamaño del scroll en vertical */
    height: 10px; /* Tamaño del scroll en horizontal */
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
  .bodyNaos::-webkit-scrollbar-thumb {
    background: #637691;
    border-radius: 8px;
  }

  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  .bodyNaos::-webkit-scrollbar-thumb:hover {
    background: #637691;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Cambiamos el fondo cuando esté en active */
  .bodyNaos::-webkit-scrollbar-thumb:active {
    background-color: #637691;
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del track */
  .bodyNaos::-webkit-scrollbar-track {
    background: #ccc;
  }

  /* Cambiamos el fondo cuando esté en active o hover */
  .bodyNaos::-webkit-scrollbar-track:hover,
  .bodyNaos::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }

  .viewport {
    overflow: auto;

    /* Make sure the inner div is not larger than the container
         * so that we have room to scroll.
         */
    max-height: 100%;

    /* Pick an arbitrary margin/padding that should be bigger
         * than the max width of all the scroll bars across
         * the devices you are targeting.
         * padding = -margin
         */
    margin-right: -100px;
    padding-right: 100px;
  }

  .notifier__notification {
    height: 80px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .filaLogo {
    text-align: center;
  }

  .filaLogo2 {
    text-align: center;
  }

  .filaLogo:hover {
    cursor: pointer;
  }

  .loader {
    text-align: center;
    margin-top: 20%;
  }

  .filaSubMenu {
    font-size: 24px;
    color: #393d4d;
    border-left: 1px solid #393d4d;
  }

  .ng-select-container {
    border: none !important;
    border-bottom: 1px solid #393d4d !important;
    border-color: silver !important;
  }

  .ng-select {
    border: none !important;
    border-bottom: 1px solid #393d4d !important;
    border-color: silver !important;
  }

  select {
    border: none !important;
    border-bottom: 1px solid grey !important;
  }

  .iconInfo {
    margin-left: 5%;
    color: silver;
  }

  .iconInfo:hover {
    cursor: pointer;
  }
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-device-width: 480px) {
  .bodyNaos {
    overflow-x: hidden;
  }

 #tarteaucitronDisclaimerAlert {
  margin-top: 10% !important;
  margin-bottom: 10% !important;
 }

  #tarteaucitronAlertBig {
    position: absolute !important;
    margin-top: 100% !important;   /* Mueve el elemento al 50% desde la parte superior */
    left: 50% !important;  /* Mueve el elemento al 50% desde la izquierda */
    transform: translate(-50%, -50%) !important; /* Ajusta para centrarlo completamente */
    background: white !important; /* Color de fondo para visualizar el elemento */
    padding: 20px !important;
    border-radius: 10px !important;
  }
  

  /* .modal {
    margin-left: 10% !important;
  } */

  .suggestions-container {
    z-index: 1060 !important;
  }

  .bodyNaos::-webkit-scrollbar {
    width: 10px; /* Tamaño del scroll en vertical */
    height: 10px; /* Tamaño del scroll en horizontal */
  }

  #contenidodiv {
    text-align: center;
  }

  .popover-body {
    width: 224px !important;
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
  .bodyNaos::-webkit-scrollbar-thumb {
    background: #637691;
    border-radius: 8px;
  }

  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  .bodyNaos::-webkit-scrollbar-thumb:hover {
    background: #637691;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Cambiamos el fondo cuando esté en active */
  .bodyNaos::-webkit-scrollbar-thumb:active {
    background-color: #637691;
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del track */
  .bodyNaos::-webkit-scrollbar-track {
    background: #ccc;
  }

  /* Cambiamos el fondo cuando esté en active o hover */
  .bodyNaos::-webkit-scrollbar-track:hover,
  .bodyNaos::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }

  .viewport {
    overflow: auto;

    /* Make sure the inner div is not larger than the container
         * so that we have room to scroll.
         */
    max-height: 100%;

    /* Pick an arbitrary margin/padding that should be bigger
         * than the max width of all the scroll bars across
         * the devices you are targeting.
         * padding = -margin
         */
    margin-right: -100px;
    padding-right: 100px;
  }

  .notifier__notification {
    height: 80px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .filaLogo {
    text-align: center;
  }

  .filaLogo2 {
    text-align: center;
  }

  .filaLogo:hover {
    cursor: pointer;
  }

  .loader {
    text-align: center;
    margin-top: 20%;
  }

  .filaSubMenu {
    font-size: 24px;
    color: #393d4d;
    border-left: 1px solid #393d4d;
  }

  .ng-select-container {
    border: none !important;
    border-bottom: 1px solid #393d4d !important;
    border-color: silver !important;
  }

  .ng-select {
    border: none !important;
    border-bottom: 1px solid #393d4d !important;
    border-color: silver !important;
  }

  select {
    border: none !important;
    border-bottom: 1px solid grey !important;
  }

  .iconInfo {
    margin-left: 5%;
    color: silver;
  }

  .iconInfo:hover {
    cursor: pointer;
  }
}
